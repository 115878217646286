define('modules/module.ce-manager',[
    'jquery',
    'underscore',
    'TweenMax',
    'modules/transition-manager',
    'modules/lib/waypoint',
    'modules/lib/waypoint-manager'
], function($, _, TweenMax, TransitionManager, Waypoint, WaypointManager)
{
    'use strict';
       
    /**
     * Class name.
     * 
     * @type String
     */
    var sName = 'CeManager';       
       
    /**
     * Content Element manager class.
     * 
     * @author Michael Bindig <mbi@sopg.de>
     * @copyright (c) 2015, SOPG GbR
     * 
     * Created: 2015-08-04
     * Updated: 2016-09-19
     * 
     * @returns {undefined}
     */
    var CeManager = function()
    {           
        /**
         * Transition Manager instance.
         * 
         * @type TransitionManager
         */
        this._oTransitionManager = new TransitionManager();
        
        /**
         * Waypoint Manager instance.
         * 
         * @type WaypointManager
         */
        this._oWaypointManager = new WaypointManager();
        
        /**
         * Scroll wrapper jQuery element.
         * 
         * @type jQuery
         */
        this._$scroller = null;                
                
        /**
         * Selector and settings object.
         * 
         * @type Object
         */
        this.o = {
            sel : {
                reloadItems   : '.js-load-it',
                scroller      : '.scroll-wrapper'
            },
            set : {
                reloadOffset : '50%',
                reloadSpeed  : 0.3,
                reloadTrans  : Circ.easeOut
            }
        };
        
        this.initialize();
    };
    
    
    /**
     * Initializes CeManager.
     * 
     * @author Michael Bindig <mbi@sopg.de>
     * 
     * Created: 2015-08-04
     * Updated: 2016-09-19
     * 
     * @returns {undefined}
     */
    CeManager.prototype.initialize = function()
    {
        log('Initializing ...');
        
        /* DEPRECATED since 2016-09-19, SOPGSITE-127:
        /* Set the scroller. */
        /*
        this._$scroller = $(this.o.sel.scroller);
        */

        this.addTransitionEventListeners()
            .initReloadItemsWaypoints();

        log('Initialized.');
    };
    
    
    /**
     * Adds transition event listeners to transition manager.
     * 
     * @author Michasel Bindig <mbi@sopg.de>
     * 
     * Created: 2015-08-04
     * Updated: 2016-09-19
     * 
     * @returns {CeManager}
     */
    CeManager.prototype.addTransitionEventListeners = function()
    {
        log('Adding event listeners to TransitionManager.');
        
        /* Init Reload Items Waypoints.  */
        this._oTransitionManager.addListener(
            TransitionManager.EVENT_TRANSITION, 
            _.bind(this.initReloadItemsWaypoints, this)
        );

        
        /* DEPRECATED since 2016-09-19, SOPGSITE-127
        /* Init parallax content elements. */
        /*
        this._oTransitionManager.addListener(
            TransitionManager.EVENT_TRANSITION, 
            _.bind(this.refreshParallaxImages, this)
        );
        */
        
        return this;
    };
    
    
    /**
     * Initializes waypoints for item reloading.
     * 
     * @author Michael Bindig <mbi@sopg.de>
     * 
     * Created: 2015-08-04
     * 
     * @returns {CeManager}
     */
    CeManager.prototype.initReloadItemsWaypoints = function()
    {
        log('Initializing items that become displayed in viewport.');
                
        var $items = $(this.o.sel.reloadItems),
            self   = this;
            
        this._oWaypointManager.clear();
        
        if (0 < $items.length) {          
            $items.each(function()
            {
                var $elem = $(this);
                /* Create a new waypoint 
                and add it to the waypoint manager. */
                self._oWaypointManager.add(new Waypoint(
                {
                    $el     : $elem,
                    offset  : self.o.set.reloadOffset,
                    handler : function()
                    {
                        TweenMax.to(
                            $elem, 
                            self.o.set.reloadSpeed, 
                            { 
                                alpha : 1, 
                                ease  : self.o.set.reloadTrans 
                            }
                        );
                    }
                }));
            });
        }
        
        return this;
    };
    
    
    /**
     * DEPRECATED since 2016-09-19, SOPGSITE-127:
     * 
     * Refreshes the parallax images, 
     * by calling $.stellar('destroy').
     * 
     * @author Michael Bindig <mbi@sopg.de>
     * 
     * Created: 2015-08-04
     * Updated: 2016-09-19
     * 
     * @deprecated since 2016-09-19, SOPGSITE-127
     * @returns {CeManager}
     */
    CeManager.prototype.refreshParallaxImages = function()
    {
        return this;
        
        log('Refreshing parallax images.');
        
        this._$scroller.stellar('destroy');
        this.initParallaxImages();
        
        return this;
    };
    
    
    /**
     * Extends jQuery Stellar, 
     * by adding a new scroll property, 
     * that is able to return the page's 
     * scrolling top offset for IE10 and IE11.
     * 
     * @author Michael Bindig <mbi@sopg.de>
     * 
     * Created: 2015-08-06
     * 
     * @returns {undefined}
     */
    CeManager.prototype.addIeScrollPropertyExtensionToStellar = function()
    {
        $.stellar.scrollProperty.ietransform = {
            getLeft: function($elem) 
            {
                return 0;
            },
            getTop : function($elem) 
            {
                /* Get the CSS Matrix3D value. */
                var sMatrix = $elem.css('transform');
                /* Get the comma-separated coordinates
                string out of the matrix string.*/
                var sCoords = sMatrix.substr(9, sMatrix.length - 10);
                /* Make the comma-separated coordinates an array. */
                var aVals   = sCoords.split(',');
                /* Make integers from the coords. */
                for (var iCnt = 0; iCnt < aVals.length; iCnt++) {
                    aVals[iCnt] = parseInt(aVals[iCnt]);
                }

                /* Return the 13th coord, for it's the top value. */
                return aVals[13] * -1;
            }
        };        
    };
    
    
    /**
     * Returns the position property string,
     * needed for Stellar configuratuion,
     * based on which browser is used.
     * 
     * @author Micheal Bindig <mbi@sopg.de>
     * 
     * Created: 2015-08-06
     * 
     * @returns {String}
     */
    CeManager.prototype.getStellarPositionProperty = function()
    {
        var sPositionProperty = 'transform';
        
        if (isIe()) {
            sPositionProperty = 'position';
        }
        
        return sPositionProperty;
    };
    
    
    /**
     * Returns the scroll property string,
     * needed for Stellar configuration,
     * based on which browser is used.
     * 
     * @author Micheal Bindig <mbi@sopg.de>
     * 
     * Created: 2015-08-06
     * 
     * @returns {String}
     */
    CeManager.prototype.getStellarScrollProperty = function()
    {
        var sScrollProperty = 'transform';
        
        if (isIe()) {
            if (isIe(11) || isIe(10)) {
                sScrollProperty = 'ietransform';
            } else if (isIe(8)) {
                sScrollProperty = 'position';
            }
        }
        
        return sScrollProperty;
    };
    
    
    /**
     * DEPRECATED since 2016-09-19, SOPGSITE-127
     * 
     * Initializes parallax effect on parallax images.
     * 
     * @author Michael Bindig <mbi@sopg.de>
     * 
     * Created: 2015-08-04
     * Updated: 2016-09-19
     * @deprecated since 2016-09-19, SOPGSITE-127
     * @returns {CeManager}
     */
    CeManager.prototype.initParallaxImages = function()
    {                              
        return this;
        
        if (0 < this._$scroller.length) {
            
            this.addIeScrollPropertyExtensionToStellar();
            
            log('Initializing ' + this._$scroller.length + ' parallax content element(s) ...');
            /* Init stellar with scroll 
            and position property "transform". */
            
            var self = this;
            
            this._$scroller.each(function()
            {
                $(this).stellar(
                {
                    scrollProperty      : self.getStellarScrollProperty(),
                    /* Added 2015-08-06, SOPGSITE-105:
                    Use position property "position" for IE browsers. */
                    positionProperty    : self.getStellarPositionProperty(),
                    responsive          : true,
                    hideDistantElements : true
                });
            });
            
            log(this._$scroller.length + ' parallax content element(s) initialized.');
        }
        
        return this;
    };
    
    
    /**
     * Logs the given content,
     * by using global sLog() function.
     * 
     * @author Michael Bindig <mbi@sopg.de>
     * 
     * Created: 2015-07-03
     * 
     * @param {mixed} mContent
     * @param {Boolean} bForce
     * @returns {undefined}
     */
    var log = function(mContent, bForce)
    {
        sLog(mContent, sName, bForce);
    };    
    
    return CeManager;
});
