define('portfolioSrv',[
    'jquery',
    'underscore',
    'TweenMax',
    'modules/transition-manager',
    'modules/scroll-manager',
    'modules/image-loader',
    'modules/lib/waypoint',
    'modules/lib/waypoint-manager'
], function($, _, TweenMax, TransitionManager, ScrollManager, loadImages, Waypoint, WaypointManager)
{
    'use strict';
       
    /**
     * Portfolio Service class.
     * 
     * @author Michael Bindig <mbi@sopg.de>
     * @copyright (c) 2015, SOPG GbR
     * 
     * Created: 2015-07-16
     * Updated: 2015-07-24
     * 
     * @returns {undefined}
     */
    var PortfolioService = function()
    {
        /**
         * Own instance.
         * 
         * @type PortfolioService
         */
        var self = this;
        
        /**
         * Class name.
         * 
         * @type String
         */
        var sName = 'PortfolioService';
                
        /**
         * CSS selectors.
         * 
         * Updated: 2015-07-24
         * 
         * @type Object
         */
        var oSel = {
            projectsContainer : '.project-wall-element .projects.container',
            project           : '.project-wall-project',
            projectImageLayer : '.image-layer',
            loadedProjectIds  : '.js-loaded-project-ids',
            projectsAmount    : '.js-projects-amount',
            loader            : '.js-ajax-project-loader'
        };
        
        /**
         * Settings object,
         * holding all TileLayerAnimation settings.
         * 
         * Updated: 2015-07-24
         * 
         * @type Object
         */
        var oSet = {
            ajaxUrl      : window.app.url.home + '/ajax/loadProjects',
            projIdAttr   : 'data-project-id',
            loadedIds    : [],
            reloadEasing : Power4.easeOut,
            reloadSpeed  : 1.0,
            projImglayerStart   : { 
                alpha : 0, 
                scale : 1
            },
            projImgLayerFadeIn  : { 
                alpha : 1,
                scale : 1,
                ease  : Circ.easeOut
            },
            projImgLayerOffset: '50%',
            projImgLayerFadeInSpeed : 1.7
        };
                
        
        /**
         * Initializes tis module 
         * by binding a click listener 
         * to the reload projects button.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-07-16
         * Updared: 2015-07-24
         * 
         * @returns {Object}
         */
        this.initialize = function()
        {
            log('Initializing Portfolio Service ...');

            this.oTransitionManager = new TransitionManager();
            this.oTransitionManager.addListener(TransitionManager.EVENT_TRANSITION, _.bind(this.registerBindings, this));
            this.oScrollManager = new ScrollManager();
            this.oWaypointManager = new WaypointManager();

            /* Set the loaded project IDs. */
            $(oSel.project).each(function()
            {
                var iId = parseInt($(this).attr(oSet.projIdAttr));
                oSet.loadedIds.push(iId);
            });                      
            
            this.registerBindings();     
            
            log('Portfolio Service initialized.');
        };


        this.registerBindings = function(){
            /* Bind the click listener to the reload button. */
            $(oSel.loader).bind('click', self._reloadProjects);
            self._initWaypoints();
        };


        this.deregisterBindings = function(){
            /* unbind the click listener */
            $(oSel.loader).unbind('click', self._reloadProjects);
        };        
                
        
        /**
         * Initializes waypoints 
         * on each project image layer.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-07-24
         * 
         * @returns {undefined}
         */
        this._initWaypoints = function()
        {
            this.oWaypointManager.clear();

            log('Initializing waypoints ...');
            var $layers = $(oSel.projectImageLayer),
                self = this;

            $layers.each(function(){
                var $layer = $(this);
                TweenMax.set($layer, oSet.projImglayerStart);
                self.oWaypointManager.add(new Waypoint({
                    $el: $layer,
                    handler : _.bind(self.animateLayer, self, $layer),
                    /* Set a 40% offset, in order not 
                    to fade in not just as the layer 
                    reaches the browser's top. */
                    offset: oSet.projImgLayerOffset
                }));
            });
            
            log('Waypoints initialized on ' + $layers.length + ' project image layers.');
        };

        this.animateLayer = function($layer){
            TweenMax.to(
                $layer, 
                oSet.projImgLayerFadeInSpeed, 
                oSet.projImgLayerFadeIn
            );
        };
        
        
        /**
         * Reloads the unloaded projects via AJAX request 
         * and appends them to the projects container.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-07-16
         * 
         * @param {Object} oClickEvnt
         * @returns {undefined}
         */
        this._reloadProjects = function(oClickEvnt)
        {
            log('Reload request has been sent.');
            var $container = $(oClickEvnt.target).closest('.content-element').find('.projects.container');    
            var sLoadedIds = $(oSel.loadedProjectIds).val();
            log('Starting AJAX request to "' + oSet.ajaxUrl + '", with params { ids : "' + sLoadedIds + '" } ...');
            
            $.ajax(
            {
                url  : oSet.ajaxUrl,
                type : 'POST',
                data : {
                    ids : sLoadedIds
                },
                complete : function(oData) 
                {   

                    /* Fly in the responded HTML code. */
                    self._flyInNewProjects($container, oData.responseText);
                    
                    self.oTransitionManager.unregisterContentBindings();
                    self.oTransitionManager.registerContentBindings();                    
                }
            });
        };        
        
        /**
         * Updates project counter after all projects are displayed
         * 
         * @jira SOPGSITE-202
         * 
         * Created: 2018-08-10
         * 
         * @param {jQuery} $container
         * @returns {undefined}
         */
        this._updateProjectCounter = function($container)
        {            
            var iLoadedIdsCnt = oSet.loadedIds.length;
            /* Iterate the projects 
            and adapt the loaded IDs array. */
            $container.find(oSel.project).each(function()
            {
                /* Get the project's ID. */
                var iId = parseInt($(this).attr(oSet.projIdAttr));
                /* Verify, that project ID isn't 
                already in the loaded IDs array. */
                if (-1 === $.inArray(iId, oSet.loadedIds)) {
                    oSet.loadedIds.push(iId);
                }
            });
            log((oSet.loadedIds.length - iLoadedIdsCnt) + ' projects were appended.');

            /* Check, if all projects are loaded 
            and remove the reload button if needed. */
            if (oSet.loadedIds.length === parseInt($(oSel.projectsAmount).val())) {
                $(oSel.loader).remove();
                log('All ' + oSet.loadedIds.length + ' published projects were loaded.');
            }

            /* Finally overwrite the hidden input field value, 
            that holds the loaded project IDs string. */
            $(oSel.loadedProjectIds).val(oSet.loadedIds.join(','));
        }
        
        /**
         * Animates the given projects HTML in.
         * 
         * @author Micheal Bindig <mbi@sopg.de>
         * 
         * Created: 2015-07-16
         * 
         * @param {jQuery} $container
         * @param {String} sHtml
         * @returns {undefined}
         */
        this._flyInNewProjects = function($container, sProjectsHtml)
        {
            var $html = $(sProjectsHtml),
                imageSources = $html.find('img')
                                .map(function(i, el){ return $(el).attr('src') })
                                .toArray()
            
            // first: load images, then append the elements to the $container
            loadImages(imageSources)
            .then(function(){
                $html.each(function(){
                    var $project = $(this);
                    $container.append($project);
                    TweenMax.set($project, { marginTop : 700 });
                    TweenMax.to($project, oSet.reloadSpeed, { marginTop : 0, ease : oSet.reloadEasing, onComplete : function(){
                        self.oScrollManager.refresh();
                        // find the imagelayer and add a waypoint for it
                        // the offset is updated in the onComplete above (because it's being animated)
                        var $imageLayer = $project.find(oSel.projectImageLayer);
                        if( $imageLayer.length && !self.oWaypointManager.hasElement($imageLayer) ){
                            self.oWaypointManager.add(new Waypoint({
                                $el: $imageLayer,
                                handler: _.bind(self.animateLayer, self, $imageLayer),
                                offset: oSet.projImgLayerOffset
                            }))
                        }
                    }});
                });
                /* SOPGSITE-202 */
                self._updateProjectCounter($container);
            })
        };
        
        
        /**
         * Logs the given content,
         * by using global sLog() function.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-07-03
         * Updated: 2015-07-15
         * 
         * @param {mixed} mContent
         * @param {Boolean} bForce
         * @returns {undefined}
         */
        var log = function(mContent, bForce)
        {
            if (sLog && 'function' === typeof sLog) {
                sLog(mContent, sName, bForce);
            }
        };
        
        
        /* Initialize 
        and return the returned. */
        return self.initialize();
    };
    
    return PortfolioService;
});
