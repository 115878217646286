define('modules/transition-manager',[
  'jquery',
  'underscore',
  'modules/lib/event',
  'modules/image-loader',
  'modules/content-service',
  'modules/lib/history-manager',
  'modules/scroll-manager',
  'TweenMax'
], function($, _, Event, Loader, ContentService, HistoryManager, ScrollManager, TweenMax)
{

    'use strict';

    var oInstance = null;

    var oOpts = {
        animationSpeed: 0.5,
        transitions: {},
        appSelector: '#app',
        scrollAreaSelector: '#app .scroll-wrapper',
        contentSelector: '#app .content-wrapper',
        contentContainerClass: 'content-wrapper',
        footerWrapperSelector: '.footer-wrap',
        transitionLinkAttribute: 'data-link-id',
        transitionLinkSelector: 'a[data-link-id]',
        transitionContainerClass: 'transition-container',
        transitionContainerHtml: '<div class="transition-container"></div>',
        contentColorSchemeStyleSelector: '#colorSchemeStyles',
        transitionColorSchemeStyleSelector: '#transitionColorSchemeStyles'
  };

  /**
   * Transition Manager constructor
   * ensures a singleton
   */
  var TransitionManager = function()
  {
    // ensure singleton
    if(oInstance){
      return oInstance;
    }
    oInstance = this;

    this.$contentContainer = $(oOpts.contentSelector);
    this.$transitionContainer = this.createTransitionNode();
    this.$footerContainer = $(oOpts.footerWrapperSelector);
    this.sNextHref = null;

    /**
     * event listeners
     * @type {Object}
     */
    this.oListeners = {
      TRANSITION: [],
      TRANSITION_COMPLETE: [],
      TRANSITION_CONTENT_LOAD: []
    };

    this.oScrollManager = new ScrollManager();
    this.oContentService = new ContentService();
    this.oHistoryManager = new HistoryManager();


    /**
     * history popstate handler:
     * when the history changes start a transition
     */
    this.oHistoryManager.onChange(function(direction, state)
    {
      var transitionMode = direction == HistoryManager.EVENT_FORWARD ? TransitionManager.MODE_FORWARD : TransitionManager.MODE_BACKWARDS;

      oInstance.oContentService.get(state)
        .then(_.bind(oInstance.startTransition, oInstance, transitionMode))
        .then(_.bind(oInstance._callListeners, oInstance, TransitionManager.EVENT_TRANSITION_COMPLETE))
        .then(function(){
            var position = oInstance.oHistoryManager.getScrollState();
            oInstance.oScrollManager.scrollToPosition(position);
        })
        .then(_.bind(oInstance.oScrollManager.scrollToAnchor, oInstance.oScrollManager));
    });
    
    /**
     * bootstrap:
     * - register page-wide bindings
     * - register content-wide bindings
     * - show scroll indicators
     */
    this.registerPageBindings();
    this.registerContentBindings();
    this.oScrollManager.showScrollIndicator();
  };

  /**
   * extend the transitionmanager's prototype
   */
  _.extend(TransitionManager.prototype, Event.prototype);

  /**
   * constants
   * - Transition mode: MODE_*
   * - Events: EVENT_*
   */
  TransitionManager.MODE_FORWARD = 'FWD';
  TransitionManager.MODE_BACKWARDS = 'BWD';
  TransitionManager.EVENT_TRANSITION = 'TRANSITION';
  TransitionManager.EVENT_TRANSITION_COMPLETE = 'TRANSITION_COMPLETE';
  TransitionManager.EVENT_TRANSITION_CONTENT_LOAD = 'TRANSITION_CONTENT_LOAD';

  /**
   * Transition mode configuration:
   * MODE_FORWARD
   */
  oOpts.transitions[TransitionManager.MODE_FORWARD] = {
    transitionContainer: {
      set: {
        zIndex: 1,
        x: '100%'
      },
      to: {
        x: '0%',
        ease: Power4.easeInOut
      }
    },
    contentContainer: {
      set: {},
      to: {
        x: '-100%',
        autoAlpha: 0,
        ease: Power4.easeInOut
      }
    }
  };

  /**
   * Transition mode configuration:
   * MODE_BACKWARDS
   */
  oOpts.transitions[TransitionManager.MODE_BACKWARDS] = {
    transitionContainer: {
      set: {
        zIndex: 1,
        x: '-100%'
      },
      to: {
        x: '0%',
        ease: Power4.easeInOut
      }
    },
    contentContainer: {
      set: {},
      to: {
        x: '100%',
        autoAlpha: 0,
        ease: Power4.easeInOut
      }
    }
  };

  /**
   * binds all events that are NOT inside the content container
   * @return {void}
   */
  TransitionManager.prototype.registerPageBindings = function()
  {
    $(oOpts.transitionLinkSelector).not(oOpts.contentSelector + ' ' + oOpts.transitionLinkSelector)
        .on('click', _.bind(this.onLinkClickCallback, this));
  };

  /**
   * removes the page-wide event listeners 
   * @return void
   */
  TransitionManager.prototype.unregisterPageBindings = function()
  {
    $(oOpts.transitionLinkSelector).not(oOpts.contentSelector + ' ' + oOpts.transitionLinkSelector)
        .off('click', _.bind(this.onLinkClickCallback, this));
  }

  /**
   * binds the links that are inside the content container
   * @return {void}
   */
  TransitionManager.prototype.registerContentBindings = function()
  {
    this.$contentContainer
        .find(oOpts.transitionLinkSelector)
        .on('click', _.bind(this.onLinkClickCallback, this));
  }; 

  /**
   * removes the event listener for elements inside the content container
   * @return void
   */
  TransitionManager.prototype.unregisterContentBindings = function()
  {
    this.$contentContainer
        .find(oOpts.transitionLinkSelector)
        .off('click', _.bind(this.onLinkClickCallback, this));
  }
  

  /**
   * when a user clicks an link:
   * - revent default action
   * - store the current scroll position
   * - start new transition
   * 
   * @param  {$.Event} e
   * @return {void}
   */
  TransitionManager.prototype.onLinkClickCallback = function(e)
  {

    // if the browser does not support history: follow link
    if(!this.oHistoryManager.supportsHistory() || window.app.system.notFound || window.app.system.adminBar){
        return;
    }

    // middle mouse button: open in new window/tab
    if (e.which === 2 || e.ctrlKey){
      return;
    }
    
    // disable default behavior
    e.preventDefault();
    
    var $el = $(e.currentTarget),
        href = $el.attr('href'),
        linkState = {
          id: parseInt($el.attr(oOpts.transitionLinkAttribute), 10),
          type: $el.attr('data-link-type')
        };
    
    // check if the desired state is already present
    if( this.oHistoryManager.isCurrentState(linkState.id) ){
        this.oScrollManager.scrollTo(0, 0, 1000);
        return;
    }

    this.oHistoryManager.setScrollState(this.oScrollManager.getScroller().y);

    this.sNextHref = href;

    // fetch content
    // start transition
    // update state
    this.oContentService.get(linkState)
      .then(_.bind(this.startTransition, this, TransitionManager.MODE_FORWARD))
      .then(_.bind(this.oHistoryManager.push, this.oHistoryManager, linkState, href))
      .then(_.bind(this.oScrollManager.scrollToAnchor, this.oScrollManager))
      .then(_.bind(this._callListeners, this, TransitionManager.EVENT_TRANSITION_COMPLETE));
  };
  

  /**
   * creates the transition node and returns it
   * @return {$el}
   */
  TransitionManager.prototype.createTransitionNode = function()
  {
    $(oOpts.transitionContainerHtml).insertBefore(oOpts.footerWrapperSelector);
    return $(oOpts.scrollAreaSelector).find('.'+ oOpts.transitionContainerClass);
  };

  /**
   * set the animation speed
   * 
   * @param {void}
   */
  TransitionManager.prototype.setAnimationSpeed = function(nSpeed)
  {
    if( _.isNumber(nSpeed) ){
      oOpts.animationSpeed = nSpeed;
    }
  };

  /**
   * transition complete callback
   * 
   * @param  {const} mode
   * @param  {Object} data
   * @param  {$.Deferred} deferred
   * @return {void}
   */
  TransitionManager.prototype.onTransitionComplete = function(mode, data, deferred)
  {
    // remove the old content container
    this.$contentContainer.remove();

    // inject the content-styles 
    $(oOpts.contentColorSchemeStyleSelector).html(data['content-styles']);

    // flip transition/content classes
    this.$transitionContainer
        .removeClass(oOpts.transitionContainerClass)
        .addClass(oOpts.contentContainerClass);


    // create new transition container
    this.$transitionContainer = this.createTransitionNode();

    // set reference to new contentContainer
    this.$contentContainer = $(oOpts.contentSelector);

    // register content-wide bindings
    this.registerContentBindings();

    // call the listeners 
    this._callListeners(TransitionManager.EVENT_TRANSITION);
    
    // refresh scrollbar because the height might have changed
    this.oScrollManager.refresh();

    // show scrollbars again
    this.oScrollManager.showScrollIndicator();

    // set content and footer translation to be at 0
    TweenMax.set(this.$contentContainer, { y: 0 });
    TweenMax.set(this.$footerContainer, { x: "0%", autoAlpha: 1 });

    /**
     * scroll to top - if the user scrolled down on the page
     * and then requests the page the scrollbar is still somewhere in the middle
     */
    this.oScrollManager.scrollTo(0, 0, 0);

    Loader(this.$contentContainer.find('img').toArray().map(function(el){
        return $(el).attr('src');
    })).always(_.bind(function(){
      this._callListeners(TransitionManager.EVENT_TRANSITION_CONTENT_LOAD);
    }, this));

    // resolve promise from startTransition()
    deferred.resolve();
  };

  /**
   * start transition takes a transition mode and html as a string as arguments
   * and returns a $.Deferred().promise().
   * The promise is resolved when the transition is completed
   *
   * @calls onTransitionComplete()
   * 
   * @param  {const} mode
   * @param  {Object} data
   * @return {Thenable/Promise}
   */
  TransitionManager.prototype.startTransition = function(mode, data)
  {
        // resolved at onTransitionComplete
    var deferred = $.Deferred(),
        // get y-offset from matrix-transform
        scrolledOffset = this.oScrollManager.getScroller().getComputedPosition();

    // update page's title
    window.document.title = data.title;

    // inject the new html into the transition container
    this.$transitionContainer.html(data.content);

    // hide scrollbars until the transition is completed
    this.oScrollManager.hideScrollIndicator();

    // inject the transition styles
    $(oOpts.transitionColorSchemeStyleSelector).html(data['transition-styles']);

    // get the transition configuration based on the transition mode
    var conf = oOpts.transitions[mode];
    /**
     * if the user has scrolled down a bit
     * we have set the y offset on the transition container
     * this ensures that the new content is right at the top
     * when it transitions into the viewport
     */
    if( scrolledOffset && scrolledOffset.y ){
      TweenMax.set(this.$transitionContainer, {
        y: -scrolledOffset.y
      });
    }

    TweenMax.set(this.$transitionContainer, conf.transitionContainer.set);
    TweenMax.set(this.$contentContainer, conf.contentContainer.set);
    TweenMax.to(this.$contentContainer, oOpts.animationSpeed, conf.contentContainer.to);
    TweenMax.to(this.$footerContainer, oOpts.animationSpeed, conf.contentContainer.to);
    TweenMax.to(this.$transitionContainer, oOpts.animationSpeed, _.extend({
      onComplete: _.bind(this.onTransitionComplete, this, mode, data, deferred)
    }, conf.transitionContainer.to));

    // return promise / the deferred is resolved at onTransitionComplete
    return deferred.promise();
  };

  return TransitionManager;
});

