/**
 * SOPG jQuery plugin, that shortens text elements, 
 * in order not to overflow parent's block height.
 * 
 * @author Michael Bindig <mbi@sopg.de>
 * @copyright (c) 2014, SOPG GbR
 * 
 * Updated: 09.09.2014
 * 
 * @param {jQuery} $
 * @returns {undefined}
 */
(function($) 
{
    'use strict';
    
    $.fn.shorten = function(oArgs)
    {
        var self  = this;
        var $elem = $(this);   
        var $offset = 16;     

        /**
         * Default settings.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * @type Object
         */
        this._oDefaults = {
            selector : 'h1, h2, p, span',
            steplen  : 7,
            suffix   : '...'
        };

        /**
         * Settings object.
         * Gets set up by the init() method.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * @type Object
         */
        this._oSettings = {};


        /**
         * Initializes the text-shortener, by setting up the settings
         * and calling the shortenText() method.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * @type Object
         */
        this._init = function()
        {
            /* Create emty oArgs object, if no args are given. */
            if ((undefined === oArgs) || ('object' !== typeof oArgs)) {
                oArgs = {};
            }
            /* Set up the settings by extending the defaults. */
            self._oSettings = jQuery.extend(self._oDefaults, oArgs);
            /* Call the shortenText() method. */
            self._shortenText();
        };
        

        /**
         * Shortens the text.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Updated: 2014-11-24
         * 
         * @return {undefined}
         */
        this._shortenText = function() 
        {
            var iOffset = 0;
            var $attr   = $elem.attr('data-shorten-offset');
            
            if ((typeof $attr !== typeof undefined) && (false !== $attr)) {
                iOffset = parseInt($elem.attr('data-shorten-offset'));
            } else {
                iOffset = $offset;
            }
                
            /* Get the height of the wrapping element. */
            var iHeight = $elem.parent().outerHeight() - iOffset;

            /* Shorten the text, until the height 
            equals the height of the wrapping element. */
            var i = 0;
            while ($elem.outerHeight() > iHeight)
            {
                i++;
                var oLast = $elem.find(self._oSettings.selector).last();
                
                if (0 < oLast.length) {
                    
                    var iLong = oLast.html().length;

                    /* Shorten! */
                    oLast.html((oLast.html().substr(0, iLong - self._oSettings.steplen) + self._oSettings.suffix).replace(/<(?:.|\n)*?>/gm, ''));

                    if ((0 === iLong) || (self._oSettings.suffix.length === iLong))
                    {
                        /* Remove the element if it's empty. */
                        oLast.remove();
                    }
                } else {
                    break;
                }
                
            }
            
            if (0 < $elem.find(self._oSettings.selector).length) {
                if ((0 < i) 
                && ('...' !== $elem.find(self._oSettings.selector).last().html().substr(-3, 3))) {
                    $elem.find(self._oSettings.selector).last().append('...');
                }
            }

        };
        
        /* Auto-initialize ... */
        self._init();
    };
    
})(jQuery);
define("text-shorten", ["jquery"], function(){});

