define('modules/module.featured-projects',[
  'jquery',
  'underscore',
  'modules/lib/waypoint',
  'modules/lib/waypoint-manager',
  'TweenMax'
], 
function($, _, Waypoint, WaypointManager, TweenMax)
{

    'use strict';

    /**
     * featured projects constructor
     * - initializes required components (WaypointManager)
     * - calls initializeWaypoints
     */
    var FeaturedProjects = function()
    {

        var self = this;

        this.oConfig = {
            projectCounter : {
                self           : '.project-counter',
                amount         : '.amount',
                count          : '.js-amount',
                text           : '.text',
                targetFontSize : 166,
                ease           : Circ.easeOut,
                timeFactor     : 0.25
            }
        };

        this.oWaypointManager = new WaypointManager();
        this.initializeWaypoints();
    };

    /**
     * Initializes project-waypoints
     * - returns early when no projects are found
     * - clears the Waypointmanager
     * - adds new Waypoints to the manager
     * 
     * @return {void}
     */
    FeaturedProjects.prototype.initializeWaypoints = function()
    {
        var self = this,
            $projCounters = $(this.oConfig.projectCounter.self);

        if( $projCounters.length === 0 ){
            return;
        }

        self.oWaypointManager.clear();

        $projCounters.each(function(){
            var $counter = $(this),
                $amount = $counter.find(self.oConfig.projectCounter.amount),
                iTargetValue = parseInt($counter.find(self.oConfig.projectCounter.count).val()),
                counter = new ProjectCounter($counter, $amount, iTargetValue);

            self.oWaypointManager.add(new Waypoint({
                $el     : $counter,
                offset  : '90%',
                handler : _.bind(counter.run, counter)
            }));
        });
    };

    /**
     * This function initializes a slot-machine alike animation
     * inside an project counter
     * 
     * @param {jQuery Element} $counter
     * @param {jQuery Element} $amount
     * @param {Number} targetValue
     */
    var ProjectCounter = function($counter, $amount, targetValue)
    {
        this.$counter = $counter;
        this.$amount = $amount;
        this.iTargetValue = targetValue;
        this.slotGroups = null;

        this.$amount.css('opacity', '1');
        this.prepareHtml();
    };

    /**
     * creates and inserts the necessary Nodes
     * @return {void}
     */
    ProjectCounter.prototype.prepareHtml = function()
    {
        // prepare the animation nodes
        // 123 => "123" -> ["1", "2", "3"] -> [DOMNode, DOMNode ...] -> append to $slot
        this.slotGroups = this.iTargetValue.toString().split('').map(function(){
            var $node = $('<div class="slot-node"></div>'),
                $slot = $('<div class="slot blur"></div>');
            
            _.chain( _.range(10) )/*.shuffle()*/
                .map(function(x){
                    return '<div>'+x+'</div>';
                })
                .each(function(html){
                    $slot.append(html);
                });

            $node.append($slot);
            return $node;
        });

        this.$amount.html(this.slotGroups);
    };

    /**
     * starts the animation
     */
    ProjectCounter.prototype.run = function()
    {
      var target = this.iTargetValue.toString().split(''),
          $blur = $('#blur feGaussianBlur');

        TweenMax.to( $blur, 0, { attr: { stdDeviation: "0,8" } } )

        _.each(this.slotGroups, function($group, i){
            var rnd = _.random(300, 700),
                targetNumber = target[i],
                $slot = $group.find('.slot'),
                index = $slot.find('div:contains('+targetNumber+')').index();

            TweenMax.set($slot, { top: -900 });
            TweenMax.to($slot, .33, {
                top: 0,
                repeat: -1,
                ease: Linear.easeOut
            });

            setTimeout(function(){
              // reset the blur
              TweenMax.to($blur, 0, { attr: { stdDeviation: "0" } });
              TweenMax.to($slot, .9, { ease: Elastic.easeOut, top: index * -100 })
            }, rnd);
        });
    };

    return FeaturedProjects;
});

