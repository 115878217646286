define('modules/lib/cache-manager',[
], function(){

  'use strict';

  var instance;

  /**
   * Cache manager
   * - ensures singleton
   */
  var CacheManager = function()
  {
    if(instance){
      return instance;
    }
    instance = this;
    this.debug = true;
    this.ttl = 1000*60*60*24; // 24h
    this._hasLocalStorage = 'localStorage' in window && window['localStorage'] !== null;
    this.storage = window.localStorage;
  };

  /**
   * save a value with a namespace-prefix and a key
   * All values are wrapped in a object with metadata (ttl)
   * 
   * @param {string} prefix
   * @param {string} key
   * @param {mixed} value
   */
  CacheManager.prototype.set = function(prefix, key, value)
  {
    if(!this._hasLocalStorage) return;

    // everey value is wrapped in a object with metadata
    var savedObject = {
      ttl: new Date((new Date()).getTime() + this.ttl),
      value: value
    };

    savedObject = JSON.stringify(savedObject);
    this.storage.setItem(prefix + ':' + key, savedObject);
  };

  /**
   * hasItem checks if a value exists for a given prefix/key
   * 
   * @param  {string}  prefix
   * @param  {string}  key
   * @return {Boolean}
   */
  CacheManager.prototype.hasItem = function(prefix, key)
  {
    if(!this._hasLocalStorage) return;
    return this.get(prefix, key) ? true : false;
  };

  /**
   * get returns a item for a given prefix/key
   * 
   * @param  {string} prefix
   * @param  {string} key
   * @return {[type]}
   */
  CacheManager.prototype.get = function(prefix, key)
  {
    if(!this._hasLocalStorage) return;

    var entry, val;

    entry = this.storage.getItem(prefix + ':' + key);
    
    if( entry ){
      val = JSON.parse(entry);

      if( val && val.ttl && new Date(val.ttl) > new Date() && !this.debug ){
        return val.value;
      }
    }
  };

  /**
   * removes a item
   * 
   * @param  {string} prefix
   * @param  {string} key
   * @return {void}
   */
  CacheManager.prototype.remove = function(prefix, key)
  {
    if(!this._hasLocalStorage) return;
    this.storage.removeItem(prefix + ':' + key);
  };

  /**
   * clears the entire storage
   * @return {void}
   */
  CacheManager.prototype.clear = function()
  {
    if(!this._hasLocalStorage) return;
    this.storage.clear();
  };

  return CacheManager;

});
