/**
 * define sliderUtils
 * 
 * @provide some useful tools
 * @param {type} $
 * @returns {undefined}
 * @version 0.1
 * @date 15/07/03
 * @author sh
 */
define(
    'modules/home-slider/slider.utils',[
        'jquery'
    ],
    function($) 
    {
        var sliderTools = function() 
        {
            'use strict';
            
            /**
             * get count of object
             */
            this.objectLength=function(obj) {
                this.iOL=0;
                if(typeof obj==='object') {
                    for(this.iKey in obj) {
                        if(obj.hasOwnProperty(this.iKey))
                            this.iOL++;
                    }
                }
                return this.iOL;
            };
            
            /**
             * check requirements are met
             * @returns {Boolean}
             */
            this.requirementsAreMet=function()
            {
                return (
                        (this.$el.length>0)
                        && (this.iSlides>0)
                );
            };
            
            /**
             * check if app is busy
             * 
             * @returns {Boolean}
             */
            this.isBusy=function()
            {
                return (
                        (this.busy!==false)
                        || (this.busyTouch!==false)
                );
            };
        };

        return sliderTools;
    }
);

