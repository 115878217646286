define('modules/module.pink-star',[
  'jquery',
  'underscore',
  'TweenMax'
], function($, _, TweenMax)
{
    'use strict';

    /**
     * Pink footer star animation class.
     * 
     * Updated: 2015-08-06
     * 
     * @returns {PinkStar}
     */
    var PinkStar = function()
    {
        /**
         * PinkStar's selectors.
         * 
         * @type Object
         */
        this.oSel = {
            star : '#footerStar > .inner'
        };
        
        this.shownHellos = [];
        
        /**
         * PinkStar's settings.
         * 
         * @type Object
         */
        this.oSet = {
            speed : {
                move     : 1,
                back     : 2,
                gravMove : 1.5,
                gravBack : 1.3
            },
            ease : {
                move     : Back.easeOut,
                back     : Elastic.easeOut,
                gravMove : Circ.ease,
                gravBack : Elastic.easeOut
            }
        };

        /* Set the element properties. */
        this.$el = $(this.oSel.star);
        this.el = this.$el[0];
        
        /* Get the initial top and left 
        value for later recovering. */
        this.iLeft = parseInt(this.$el.css('left'));
        this.iTop = parseInt(this.$el.css('top'));
        
        /* Set the delta 
        values initially to 0. */
        this.iDeltaStarDragX = 0;
        this.iDeltaStarDragY = 0;

        this.initialize();
    };


    PinkStar.prototype.updateElement = function()
    {
        if( this.$el.length === 0 ) return;

        this.$el = $(this.oSel.star);
        this.el = this.$el[0];
        this.initialize();
    };


    PinkStar.prototype.initialize = function()
    {
        if( this.$el.length === 0 ) return;

        this.el.onmousedown = _.bind(this.dragStart, this);
        this._addPinkStarGravity();
    };


    PinkStar.prototype._addPinkStarGravity = function()
    {
        if (0 < this.$el.length) {
            this.iW = this.$el.parent().width();
            this.iH = this.$el.parent().height();
            this.nOrigLeft = parseInt(this.$el.css('left'));
            this.nOrigTop = parseInt(this.$el.css('top'));           

            this.$el.parent().bind('mouseleave', _.bind(this.onMouseLeave, this));
            
            /* Bind a mousemove handler to both,
            the star element and its container. */
            this.$el.parent().bind('mousemove', _.bind(this.onMouseMove, this));
            this.$el.bind('mousemove', _.bind(this.onMouseMove, this));
        }
    };


    PinkStar.prototype.onMouseMove = function(oEvnt)
    {        
        /* Return immediately, if current target is the star, 
        because its offset values are different to them from its container. */
        if ($(oEvnt.currentTarget).hasClass('inner') || this._bMovingBack) return;
           
        var iX = oEvnt.offsetX;
        var iY = oEvnt.offsetY;      

        TweenMax.to(
            this.$el, 
            this.oSet.speed.gravMove, 
            { 
                left : iX - 10, 
                top  : iY - 10,
                ease : this.oSet.ease.gravMove 
            }
        );
    };


    PinkStar.prototype.onMouseLeave = function()
    {
        if (this._bMovingBack) return;

        TweenMax.to(
            this.$el, 
            this.oSet.speed.gravBack, 
            { 
                left : this.nOrigLeft, 
                top  : this.nOrigTop, 
                ease : this.oSet.ease.gravBack 
            }
        );
    };


    PinkStar.prototype.dragStart = function(oEvnt)
    {      
        oEvnt.preventDefault();
        this.el.onmousedown = null;

        this._xOffset = oEvnt.offsetX;
        this._yOffset = oEvnt.offsetY;

        this.$el.addClass('dragging');

        this.iDeltaStarDragX = oEvnt.pageX - this._xOffset - parseInt(this.$el.css('left')) + 10;
        this.iDeltaStarDragY = oEvnt.pageY - this._yOffset - parseInt(this.$el.css('top')) + 10;
        
        window.onmousemove = _.bind(this.doDrag, this);
        window.onmouseup = _.bind(this.dragStop, this);
    };


    PinkStar.prototype.dragStop = function(oEvnt)
    {
        window.onmousemove = null;
        window.onmouseup = null;

        console.log(oEvnt);

        var el = document.elementFromPoint(oEvnt.x || oEvnt.screenX, oEvnt.y || oEvnt.screenY);

        if( $(el).attr('alt') === 'SOPG' ){
            $('#bluescreen').show();
        }
        

        this.toggleHelloWorld();


        var self = this;

        this.$el.removeClass('dragging');
        this._bMovingBack = true;
        
        TweenMax.to(
            this.$el, 
            this.oSet.speed.back, 
            { 
                left      : this.nOrigLeft,
                top       : this.nOrigTop,
                overwrite : true,
                ease      : this.oSet.ease.back,
            }
        );
        
        window.setTimeout(function()
        {
            self._bMovingBack = false;
        }, this.oSet.speed.back * 0.75);

        this.el.onmousedown = _.bind(this.dragStart, this);
    };


    PinkStar.prototype.doDrag = function(event)
    {
        var self = this;
        this._bMovingBack = true;
        var xOffset = (this.iDeltaStarDragX - event.pageX) * -1;
        var yOffset = (this.iDeltaStarDragY - event.pageY) * -1;

        TweenMax.to(
            this.$el, 
            this.oSet.speed.move, 
            { 
                css : {
                    left : xOffset,
                    top  : yOffset
                }, 
                overwrite : true,
                ease : self.oSet.ease.back,
                onComplete: function()
                {
                    self._bMovingBack = false;
                }
            }
        );
    };
    
    
    PinkStar.prototype.toggleHelloWorld = function()
    {
        var aHellos = [
            'Hello World!', '¡Hola mundo!', 'Hallo Welt!', 'Bonjour tout le monde!', 'Hei, verden!', 'Saluton mondo!', 'Olá, mundo!', 'Hallo wrâld!',
            'שלום עולם!', 'Halo dunia!', 'Halló heimur!', 'こんにちは、世界！', 'Hola món!', '세계 안녕하세요!', 'Bonghjornu mondu!', 'Pozdrav svijetu!',
            'Salve mundi!', 'Labas pasauli!', 'Здраво светот!', 'Сайн байна уу Дэлхий!', 'Hej världen!', 'Привет мир!', 'Ahoj všetci!', 'Jambo dunia!',
            'Ahoj všichni!', 'Merhaba dünya!', 'Hello világ!', 'Xin chào thế giới!', 'Helo byd!', 'Sawubona emhlabeni!'
        ];
        
        var $hw = $('#helloWorld');
        var iIdx = Math.floor(Math.random() * aHellos.length);
        var sHello = aHellos[iIdx];
        
        $hw.html( sHello.replace(/./g, "<span>$&</span>").replace(/\s/g, "&nbsp;"));
        					
		TweenMax.staggerFromTo( $hw.find("span"), 0.15, {autoAlpha:0, scale:7}, {autoAlpha:1, scale:1}, 0.05 );	
    };

    return PinkStar;
});
