define('ce-slider',[
    'jquery',
    'TweenMax',   
    'Flickity',
//    'galleria',
    'jquery-cycle'
], function($, TweenMax, Flickity)
{
    'use strict';
       
    /**
     * Content Element Slider class.
     * 
     * @author Michael Bindig <mbi@sopg.de>
     * @copyright (c) 2016, SOPG
     * 
     * Created: 2015-07-30
     * Updated: 2016-10-13
     * 
     * @returns {undefined}
     */
    var CeSlider = function(oScrollManager)
    {
        /**
         * Own instance.
         * 
         * @type CeSlider
         */
        var self = this;
        
        /**
         * Class name.
         * 
         * @type String
         */
        var sName = 'CeSlider';
                
        /**
         * CE Slider's selectors.
         * 
         * @type Object
         */
        var oSel = {
            slider : {
                galleryCe      : '.slider-gallery-element',
                slideContainer : '.slider-gallery',
                slide          : '.item',
                slider         : '.slider-container',
                cycleSlide     : '.cycle-slide',
                pager          : '.slider-gallery-pager',
                pagerItem      : '.pager-item',
                prev           : '.slider-prev',
                next           : '.slider-next'
            }
        };
        
        /**
         * CE Slider's settings.
         * 
         * @type Objects
         */
        var oSet = {
            slider : {
                swipable      : true,
                pauseOnHover  : true,
                pagerTemplate : '<div class="pager-item border-color-from-cc1 cs-border-from-title"></div>',
                manualSpeed   : 0.2,
                activePager   : 'active',
                withIdleMode  : false,
                mobileFactor  : 1,
                cellAlign     : 'left',
                lazyLoad      : false,
                carousel      : true,
                autoplay      : true,
                fx : {
                    attr    : 'data-animation-style',
                    loading : 'load',
                    sliding : 'slide'
                },
                transition : {    
                    sliding : {
                        speed  : 0.75,
                        easing : Power3.ease,
                        wait   : 5 
                    },
                    loading : {
                        speed  : 0.25,
                        easing : SteppedEase.config(4),
                        wait   : 5
                    }
                }
            },
            classes : {
                ready : 'ready'
            }
        };
        
        /**
         * Array of all CE sliders, 
         * found in the DOM.
         * 
         * @type Array
         */
        this._aSliders = [];
      
        /**
         * Array of CSS IDs from the 
         * already initialized sliders.
         * 
         * @type Array 
         */
        this._aInitializedIds = [];
        
        /**
         * Scroll Manager instance.
         * 
         * @type ScrollManager
         */
        this._oScrollManager = oScrollManager;
        
        /**
         * Initializes all Content Element Sliders, 
         * that can be found in the DOM.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-07-30
         * Updated: 2016-10-13
         * 
         * @returns {undefined}
         */
        this.initialize = function()
        {
            log('Initializing ...');

            var $sliders = $(oSel.slider.slider);
            
            if (0 < $sliders.length) {
                log('Found ' + $sliders.length + ' content element slider(s).');
                self._aSliders = $sliders;
                self.initSliders();
            }
            log('Initialized.');
            
            return self;
        };
        
        
        /**
         * Initializes all sliders, 
         * stored in the sliders property.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-07-30
         * Updated: 2016-10-13
         * 
         * @returns {undefined}
         */
        this.initSliders = function()
        {
            self._aSliders.each(function()
            {
                var $container = $(this);
                var $slides = $container.find(oSel.slider.slide);

                if ((0 < $container.length) 
                && (1 < $slides.length)) {
                    
                    /* Get requested animation type. */
                    var sAnim = $container.attr(oSet.slider.fx.attr);
                    
                    /* Initialize Flickity, if animation type is "slide". */
                    if (oSet.slider.fx.sliding === sAnim) {
                        self.initFlickitySlider($container);
                    /* Initialize Cycle, if animation type is "load". */
                    } else if (oSet.slider.fx.loading === sAnim) {
                        self.initCycleSlider($container);
                    }
                }
            });            
        };   
        
        
        /**
         * Initializes Flickity 
         * on the slider in the 
         * given slider container.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2016-10-12
         * Updated: 2016-10-13
         * 
         * @param {jQuery} $container
         * @returns {undefined}
         */
        this.initFlickitySlider = function($container)
        {            
            var $slider = $container.find(oSel.slider.slideContainer);
            
            log('Initializing content element slider "#' + $slider.attr('id') + '" with Flickity.');
            
            var $pager = $container.parents(oSel.slider.galleryCe).find(oSel.slider.pager);
            
            /* Create all pager items. */
            $slider.find(oSel.slider.slide).each(function()
            {
                var $pagerItem = $(oSet.slider.pagerTemplate);                
                $pager.append($pagerItem);
            });
            
            /* Bind a corresponding slide to pager item click. */
            $pager.on('click', oSel.slider.pagerItem, function()
            {
                var iIdx = $(this).index();
                $slider.flickity('select', iIdx);
            });
                        
            /* Initialize Flickity. */   
            $slider.flickity(
            {
                cellSelector    : oSel.slider.slide,
                cellAlign       : oSet.slider.cellAlign,
                lazyLoad        : oSet.slider.lazyLoad,
                wrapAround      : oSet.slider.carousel,
                autoPlay        : oSet.slider.autoplay,
                prevNextButtons : false,
                pageDots        : false,
                imagesLoaded    : true
            });
            
            /* Set the Flickiry object. */
            var oFlickity = $slider.data('flickity');
            
            /* Update the pager on Flickity slide 
            and announce "ready" state on first select. */
            $slider.on('cellSelect', function() 
            {
                if ('undefined' === typeof $slider.initialized) {
                    /* Finally, set the "ready" class. */
                    $container.addClass(oSet.classes.ready);
                    $slider.initialized = true;
                    /* Added 2016-06-27, SOPGSITE-114:
                    Push the current slider CSS ID in the initialized array. */
                    self._aInitializedIds.push($slider.attr('id'));
                    /* Added 2016-06-27, SOPGSITE-113:
                    Refresh iScroll! */
                    self._oScrollManager.refresh();

                    log('Content element slider "#' + $slider.attr('id') + '" initialized with Flickity.');
                }
                
                var $pagerItems = $pager.find(oSel.slider.pagerItem);
                
                $pagerItems.filter('.' + oSet.slider.activePager).removeClass(oSet.slider.activePager);
                $pagerItems.eq(oFlickity.selectedIndex).addClass(oSet.slider.activePager);
            });

            /* Bind a callback to next button click. */
            $container.parents(oSel.slider.galleryCe).find(oSel.slider.next).on('click', function()
            {
                $slider.flickity('next');
            });

            /* Bind a callback to previous button click. */
            $container.parents(oSel.slider.galleryCe).find(oSel.slider.prev).on('click', function()
            {
                $slider.flickity('previous');
            });
            
            /* Stop autop-play on user interaction. */
            $container.parents(oSel.slider.galleryCe).find(oSel.slider.prev + ', ' + oSel.slider.next + ', ' + oSel.slider.pagerItem).on('click', function()
            {
                oFlickity.stopPlayer();
            });
        };
                
        
        /**
         * Initializes the given slider 
         * by initializing jQuery Cycle2 on it.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-07-30
         * Updated: 2016-10-13
         * 
         * @param {jQuery} $slider
         * @returns {undefined}
         */
        this.initCycleSlider = function($container)
        {
            var $slider = $container.find(oSel.slider.slideContainer);
            
            log('Initializing content element slider "#' + $slider.attr('id') + '" with Cycle2.');
            
            var bIsTouch = $('html').hasClass('touch');
            
            /* Bind a custom transition handler 
            to the cycle-before event. */
            $slider.on('cycle-before', self.tweenTheTransition);
            
            $slider.on('cycle-initialized', function(oEvnt, sHash)
            {
                $container.addClass(oSet.classes.ready);
                /* Added 2016-06-27, SOPGSITE-114:
                Push the current slider CSS ID in the initialized array. */
                self._aInitializedIds.push($slider.attr('id'));
                /* Added 2016-06-27, SOPGSITE-113:
                Refresh iScroll! */
                self._oScrollManager.refresh();
                log('Content element slider "#' + $slider.attr('id') + '" initialized with Cycle2.');
            });
            
            /* Construct the Cycle2 settings object. */
            var oSliderSettings = {
                slides           : '> ' + oSel.slider.slide,
                /* Set transition effect to "custom", 
                in order to implement own transition. */
                fx               : 'custom',
                pauseOnHover     : oSet.slider.pauseOnHover,
                pagerActiveClass : oSet.slider.activePager,
                swipeFx          : 'custom',
                swipe            : oSet.slider.swipable,
                pager            : $container.parents(oSel.slider.galleryCe).find(oSel.slider.pager),
                pagerTemplate    : oSet.slider.pagerTemplate,
                manualSpeed      : oSet.slider.manualSpeed * 1000,
                log              : ((bGlobalDebug) ? bGlobalDebug : false)
            };
            
            var sAnimStyle = $container.attr('data-animation-style');
            
            switch (sAnimStyle) {
                case oSet.slider.fx.sliding:
                    /* Set the time between the transitions 
                    (multiply it with 1000, to get milliseconds. */
                    oSliderSettings.timeout = oSet.slider.transition.sliding.wait * 1000;
                    /* Multiply the settings' speed with 1000 
                    in order to get milliseconds. */
                    oSliderSettings.speed = oSet.slider.transition.sliding.speed * 1000;
                    break;
                case oSet.slider.fx.loading:
                    /* Set the time between the transitions 
                    (multiply it with 1000, to get milliseconds. */
                    oSliderSettings.timeout = oSet.slider.transition.loading.wait * 1000;
                    /* Multiply the settings' speed with 1000 
                    in order to get milliseconds. */
                    oSliderSettings.speed = oSet.slider.transition.loading.speed * 1000;                    
                    break;
                default:
                    log('Invalid animation style: "' + sAnimStyle + '"');
                    break;
            }
            
            $slider.cycle(oSliderSettings);
            
            /* Added 2016-06-29, SOPGSITE-115:
            Bid a click listener to the slider's previos button. */
            $container.parents(oSel.slider.galleryCe).find(oSel.slider.prev).bind('click', function()
            {
                $slider.cycle('prev');
            });
            
            /* Added 2016-06-29, SOPGSITE-115:
            Bid a click listener to the slider's next button. */
            $container.parents(oSel.slider.galleryCe).find(oSel.slider.next).bind('click', function()
            {
                $slider.cycle('next');
            });
            
            $container.parents(oSel.slider.galleryCe).find(oSel.slider.next + ', ' + oSel.slider.prev + ', ' + oSel.slider.pager).on('click', function()
            {
                $slider.cycle('pause');
            });
        };
        
        
        /**
         * Tweens the jQuery Cycle2 transition, 
         * if it gets used as callback 
         * for the "cycle-before" event.
         * 
         * Created: 2015-07-30
         * Updated: 2016-10-13
         * 
         * @param {Object} oEvent
         * @param {Object} oSlideOptions
         * @param {DOMelement} currentSlideElem
         * @param {DOMelement} nextSlideElem
         * @param {String} forwardFlac
         * @returns {undefined}
         */
        this.tweenTheTransition = function(oEvent, oSlideOptions, currentSlideElem, nextSlideElem, forwardFlac)
        {
            var $curr = $(currentSlideElem);
            var $next = $(nextSlideElem);            
            
            var sAnimStyle = $(this).parents(oSel.slider.slider).attr('data-animation-style');
            
            switch (sAnimStyle) {
                case oSet.slider.fx.loading:
                    self.performLoading($curr, $next);
                    break;
                case oSet.slider.fx.sliding:
                    self.performSliding($curr, $next);
                    break;
                default:
                    log('Invalid animation style: "' + sAnimStyle + '"');
                    break;
            }            
        };
        
        
        /**
         * Performs a Greensock loading animation, 
         * based on the given $nextSlide and $currentSlide.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-07-31
         * 
         * @param {jQuery} $currentSlide
         * @param {jQuery} $nextSlide
         * @returns {undefined}
         */
        this.performLoading = function($currentSlide, $nextSlide)
        {
            
            TweenMax.to($currentSlide, oSet.slider.transition.loading.speed, { alpha : 0, onComplete : function()
            {
                TweenMax.set($nextSlide, { visibility : 'visible', height: '30%'});
                TweenMax.to($nextSlide, oSet.slider.transition.loading.speed, { height: '100%', ease : oSet.slider.transition.loading.easing });
            } });
        };
        
        
        /**
         * Performs a Greensock sliding animation, 
         * based on the given $nextSlide and $currentSlide.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-07-31
         * 
         * @param {jQuery} $currentSlide
         * @param {jQuery} $nextSlide
         * @returns {undefined}
         */        
        this.performSliding = function($currentSlide, $nextSlide)
        {
            TweenMax.set($nextSlide, { left: '100%', visibility : 'visible'});
            
            TweenMax.to($currentSlide, oSet.slider.transition.sliding.speed, { left : '-100%', ease : oSet.slider.transition.sliding.easing });
            TweenMax.to($nextSlide, oSet.slider.transition.sliding.speed, { left : 0, ease : oSet.slider.transition.sliding.easing });            
        };
        
        
        /**
         * Logs the given content,
         * by using global sLog() function.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-07-03
         * 
         * @param {mixed} mContent
         * @param {Boolean} bForce
         * @returns {undefined}
         */
        var log = function(mContent, bForce)
        {
            sLog(mContent, sName, bForce);
        };
        
        
        /* Initialize 
        and return the returned. */
        return self.initialize();
    };
    
    return CeSlider;
});
