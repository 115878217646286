define('modules/lib/event',[
  'underscore'
], function(_){

  'use strict';

  /**
   * use it with:
   *
   * var MyEventInheritor = function(){}
   * MyEventInheritor.prototype = Object.create(Event.prototype)
   * 
   */
  var Event = function(){};

  /**
   * adds a listener
   * @param {string} event
   * @param {function} func
   */
  Event.prototype.addListener = function(event, func)
  {
    if(!_.isFunction(func)) throw new TypeError("this is not a function");
    this.oListeners[event].push(func);
  };

  /**
   * removes a listener
   * @param  {string} event
   * @param  {function} func
   * @return {void}
   */
  Event.prototype.removeListener = function(event, func)
  {
    if(!_.isFunction(func)) throw new TypeError("this is not a function");
    this.oListeners[event] = _.without(this.oListeners[event], _.findWhere(this.oListeners[event], func));
  };

  /**
   * internal call method which invokes all listeners for a given event
   * 
   * @param  {string} event
   * @param  {mixed} data
   * @return {void}
   */
  Event.prototype._callListeners = function(event, data)
  {
    _.each(this.oListeners[event], function(func)
    {
      func(data);
    });
  };

  return Event;
});
