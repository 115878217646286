define('modules/content-animation',[
  'jquery',
  'underscore',
  'TweenMax',
  'modules/lib/waypoint',
  'modules/lib/waypoint-manager'
], function($, _, TweenMax, Waypoint, WaypointManager){
  
  /**
   * ContentAnimation Constructor
   */
  var ContentAnimation = function(){
    this.oWaypointManager = new WaypointManager();
    this.$elements = [];

    this.initialize();
  };

  /**
   * initializes the waypoints
   * @return {void}
   */
  ContentAnimation.prototype.initialize = function()
  {
    this.initializeInstagram();

  };

  ContentAnimation.prototype.initializeInstagram = function()
  {
    var self = this;

    var $elements = $('.instagram-wall-wrapper img:not(.instagram-icon), .instagram-wall-wrapper .instagram-image.instagram-follow-us');

    _.each($elements, function(el, i){
      var $el = $(el),
          delay = _.random(0, 300);

      TweenMax.set($el, { autoAlpha: 0 });

      self.oWaypointManager.add(new Waypoint({
        $el: $el,
        offset: '90%',
        handler: function(){
          TweenMax.to($el, .75, { delay: delay/1000, autoAlpha: 1 })
        }
      }))

    });

  };

  /**
   * currently not used
   * @return {[type]} [description]
   */
  ContentAnimation.prototype.initializeContentElements = function()
  {
    var self = this;
    
    this.oWaypointManager.clear();

    var $elements = $('.content-element:not(.parallax-image-element):not(:first) > .inner');
    $elements = $elements.add( '.content-element .grid-33 .grid-66' );
    $elements = $elements.add( '.content-element .project-wall-project:not(:first)' );

    _.each($elements, function(el){

      var $el = $(el),
          rnd = _.random(5, 150);

      TweenMax.set($el, { top: '80px', position: 'relative', autoAlpha: 0 });

      self.oWaypointManager.add(new Waypoint({
        $el: $el,
        offset: '100%',
        handler: function(){
          TweenMax.to($el, .5, { delay: rnd/10, top: 0, ease: Sine.easeInOut, autoAlpha: 1});
        }
      }))
    })
  };

  return ContentAnimation;
});
