define('modules/module.ce-parallax-image',[
    'jquery'
], function($)
{
    'use strict';
       
    /**
     * Content Element Parallax Image class.
     * 
     * @author Michael Bindig <mbi@sopg.de>
     * @copyright (c) 2016, SOPG
     * 
     * Created: 2016-09-19
     * 
     * @returns {undefined}
     */
    var CeParallaxImage = function(oScrollManager)
    {
        /**
         * Own instance.
         * 
         * @type CeSlider
         */
        var self = this;
        
        /**
         * Class name.
         * 
         * @type String
         */
        var sName = 'CeParallaxImage';
                
        /**
         * CE Slider's selectors.
         * 
         * @type Object
         */
        var oSel = {
            prallaxContainer : '.parallax-image-wrapper'
        };
             
        /**
         * Flac that determines, whether 
         * CSS3 3D transforms are supported.
         * 
         * @type Boolean
         */
        var bTransform3d = false;
          
        /**
         * Parallax elements jQuery collection.
         * 
         * @type jQuery
         */
        this._$parallaxElems = null;
        
        /**
         * Scroll Manager instance.
         * 
         * @type ScrollManager
         */
        this._oScrollManager = null;
        
        /**
         * Initializes all Content Element Sliders, 
         * that can be found in the DOM.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2016-09-19
         * 
         * @returns {undefined}
         */
        this.initialize = function()
        {
            log('Initializing ...');

            self._oScrollManager = oScrollManager;
            self._$parallaxElems = $(oSel.prallaxContainer);
            
            self.processParallaxElems();
            self._oScrollManager.getScroller().on('scroll', self.processParallaxElems);
            
            self._$parallaxElems.each( function(){
                var $parallaxContainer = $(this);
                var $parallaxImage = $parallaxContainer.find($('img'));
                self.adjustMaxParallaxContainerHeight($parallaxContainer, $parallaxImage);    
                $( window ).resize(function()
                {
                    self.adjustMaxParallaxContainerHeight($parallaxContainer, $parallaxImage);
                });     
            });

            log('Initialized.');
            
            return self;
        };
        
        
        /**
         * Adapts all parallax elements, 
         * based on the current scroll position, 
         * if they are currently visible in the viewport.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2016-08-25
         * Updated: 2016-08-26
         * 
         * @returns {undefined}
         */
        this.processParallaxElems = function()
        {
            var $this, 
                iDistanceToScreenTop, 
                iParallaxHeight, 
                $img, 
                iImageHeight,
                fPercScreenDistance, 
                iNewTop;
            
            var iScrollTop = $(window).scrollTop();
            var iScreenHeight = $(window).height();
            
            self._$parallaxElems.each(function()
            {
                $this = $(this);
                
                iDistanceToScreenTop = $this.offset().top - iScrollTop;
                iParallaxHeight = $this.height();

                /* Only adapt parallax image's top position, 
                if parallax element is visible in viewport. */
                if (iDistanceToScreenTop + iParallaxHeight >= 0 
                && (iDistanceToScreenTop < iScreenHeight)) {                    

                    $img = $this.find('img').eq(0);
                    iImageHeight = $img.height();
                    fPercScreenDistance = (iDistanceToScreenTop / iScreenHeight) ;

                    iNewTop = -(Math.round((fPercScreenDistance * (iImageHeight - iParallaxHeight))));
                    
                    /* Check, if translate3d CSS3 property is 
                    supported and use top positioning, if not. */
                    if (bTransform3d) {
                        $img.css('transform', 'translate3d(0, ' + iNewTop + 'px, 0)');
                    } else {
                        $img.css('top', iNewTop);
                    }
                }
            });
        };
                
        
        this.adjustMaxParallaxContainerHeight = function($parallaxContainer,$parallaxImage)
        {
            var maxHight = $parallaxImage.height();
            $parallaxContainer.css('max-height', maxHight);
        };
        
        
        /**
         * Logs the given content,
         * by using global sLog() function.
         * 
         * @author Michael Bindig <mbi@sopg.de>
         * 
         * Created: 2015-07-03
         * 
         * @param {mixed} mContent
         * @param {Boolean} bForce
         * @returns {undefined}
         */
        var log = function(mContent, bForce)
        {
            sLog(mContent, sName, bForce);
        };
        
        
        /* Initialize 
        and return the returned. */
        return self.initialize();
    };
    
    return CeParallaxImage;
});
