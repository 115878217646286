define('modules/image-loader',[
    'jquery',
    'underscore',
  ], function($, _) {
    'use strict';

    /**
     * usage:
     * 
     * @param  {String|Array of Strings} sourceOrSources
     * @return {Promise}
     */
    return function(sourceOrSources) {

      var deferred = $.Deferred(),
          sourceCount = 1,
          loaded = 0;

      // check type: array/string
      if (_.isArray(sourceOrSources)) {
        if(sourceOrSources.length > 0){
          sourceCount = sourceOrSources.length;
          _.map(sourceOrSources, preload);
        }else{
          deferred.resolve();
        }
        
      }else if (_.isString(sourceOrSources)) {
        if( sourceOrSources.length > 0 ){
          preload(sourceOrSources);
        }else{
          deferred.resolve();
        }
      }else {
        throw new TypeError('invalid argument for preloader', sourceOrSources);
      }

      function preload(src) {
        var img = new Image();

        if( src.length !== 0 ){

          var onLoad = function() {
            if (++loaded === sourceCount) {
              deferred.resolve();
            }
          };

          img.src = src;
          img.onload = onLoad;
          img.onerror = onLoad;
          
        }else{
          --sourceCount;
        }
      }

      return deferred.promise();
    };
  });

