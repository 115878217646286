define('modules/lib/waypoint-manager',[
  'jquery',
  'underscore',
  'modules/lib/event'
], function($, _, Event){
  'use strict';

  var WaypointManager = function()
  {
    this._aWaypoints = [];
  };

  _.extend(WaypointManager.prototye, Event.prototype);

  WaypointManager.prototype.add = function(waypoint)
  {
    this._aWaypoints.push(waypoint);
  };

  WaypointManager.prototype.remove = function(waypoint)
  {
    this._aWaypoints = _.without(this._aWaypoints, _.findWhere(this._aWaypoints, waypoint));
  };

  WaypointManager.prototype.clear = function()
  {
    if( !_.isEmpty(this._aWaypoints) ){
      _.each(this._aWaypoints, function(waypoint)
      {
        waypoint.destroy();
      });
    }
    this._aWaypoints = [];
  };

  WaypointManager.prototype.update = function()
  {
    var aCleanedWaypoints = [];
    _.each(this._aWaypoints, function(waypoint)
    {
      if( !$.exists(document, waypoint.$el.get(0)) ){
        return waypoint.destroy();
      }
      aCleanedWaypoints.push(waypoint);
    });
    this._aWaypoints = aCleanedWaypoints;
  };

  WaypointManager.prototype.hasElement = function($el)
  {
    var found = _.find(this._aWaypoints, function(waypoint)
    {
      return waypoint.getElement().get(0) === $el.get(0);
    });
    return found ? true : false;
  };

  return WaypointManager;
});
