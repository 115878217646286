/**
 * define sliderEvent
 * 
 * @provide add event listener
 * @param {type} $
 * @param {type} _
 * @returns {undefined}
 * @version 0.1
 * @date 15/07/03
 * @author sh
 */
define(
    'modules/home-slider/slider.event',[
        'jquery',
        'underscore'
    ],
    function($,_)
    {
        'use strict';

        var sliderEvent=function()
        {
            var self=this;
            
            /**
             * init all events
             * 
             * @returns {undefined}
             */
            this.initEvents=function()
            {
                this.positionMegaSliderBg();
                this.resize();
                this.hover();
                this.navigation();
                this.pagination();
                this.touch();
            };
            
            /**
             * listener on window resize
             * 
             * @returns {undefined}
             */
            this.resize=function()
            {
                $(window).on('resize',_.debounce(
                        function() {
                            self.onResize();
                        },
                        this.options.event.resizeDebounce
                ));
            };
            
            /**
             * listener on mouseover
             * 
             * @returns {undefined}
             */
            this.hover=function()
            {
                if(false===this.isTouch) {
                    this.onMouseNav();
                }
            };
            
            /**
             * add listener on navigation
             * 
             * @returns {undefined}
             */
            this.navigation=function()
            {
                if(this.iSlider<=1) {
                    return;
                }
                
                this.$prev.click(function() {
                    if(self.isBusy()!==false) {
                        return;
                    }
                    /** @see SOPGSITE-45: enabel loop
                    if(self.current<=0) {
                        return;
                    }
                    */
                    self.resetAutoplay();
                    
                    self.goto=self.current-1;
                    if(self.current<0) {
                        self.goto=self.iSlidesIndexed;
                    }
                    self.triggerAnimate();
                });
                
                this.$next.click(function() {
                    if(self.isBusy()!==false) {
                        return;
                    }
                    /** @see SOPGSITE-45: enabel loop
                    if(self.current>=self.iSlidesIndexed) {
                        return;
                    }
                    */
                    self.resetAutoplay();
                    
                    self.goto=self.current+1;
                    if(self.current>self.iSlidesIndexed) {
                        self.goto=0;
                    }
                    self.triggerAnimate();
                });
            };
            
            /**
             * add listener on pagination
             * 
             * @returns {undefined}
             */
            this.pagination=function()
            {
                if(this.iSlider<=1) {
                    return;
                }
                
                for(var i=0,y=this.objectLength(this.$pagination);i<y;i++) {
                    this.$pagination[i].click(function(){
                        if(self.isBusy()!==false) {
                            return;
                        }
                        self.resetAutoplay();
                        
                        self.goto=$(this).data(self.data.goto);
                        self.triggerAnimate();
                    });
                }
            };
            
            /**
             * add listener for touch devices
             * 
             * @returns {undefined}
             */
            this.touch=function()
            {
                if(true!==this.isTouch) {
                    return;
                }
                
                $(window).on('orientationchange',function() {
                    self.onResize();
                });
                
                //add listener on touchstart to store init position
                this.$ul.on('touchstart', function(e) {
                    self.iTouchStart=self.pageX(e);
                    self.iTouchStartY=self.pageY(e);
                });
                
                //add listener on touchmove
                this.$ul.on('touchmove', function(e) {
                    self.resetAutoplay();
                    self.handleSwipe(e);
                });
                
                //add listener on touchend to mark end of current swipe
                this.$ul.on('touchend',function(e) {
                    self.handleSwipeEnd(e);
                });
            };
            
            /**
             * add listener on mouse event concerning navigation
             */
            this.onMouseNav=function()
            {
                /**
                 * add listener on element itself
                 */
                if(false===this.isTouch) {
                    this.$el.on('mouseenter' + ((typeof this.options.event.ns.navigation === 'string') ? '.' + this.options.event.ns.navigation : null),function() {
                        TweenMax.to([self.$prev,self.$next],self.options.nav.duration,{
                            autoAlpha:self.options.nav.alpha,
                            ease:self.options.nav.ease
                        });
                    });
                    this.$el.on('mouseleave' + ((typeof this.options.event.ns.navigation === 'string') ? '.' + this.options.event.ns.navigation : null),function() {
                        TweenMax.to([self.$prev,self.$next],self.options.nav.duration,{
                            autoAlpha:0,
                            ease:self.options.nav.ease
                        });
                    });
                
                    /**
                     * mimic mouseover once since mousepointer might initial upon slider element
                     */
                    var oHover=document.querySelector(':hover');
                    var oHoverNew;
                    this.bHoverOnInit=false;
                    while (oHover) {
                        oHoverNew = oHover;
                        oHover = oHoverNew.querySelector(':hover');
                        if(oHover===this.$el[0]) {
                            this.bHoverOnInit=true;
                            oHover=null;
                        }
                    }
                }
                
                /**
                 * add listener on nav
                 */
                this.$prev.mouseover(function(e) {
                    if(self.current<=0) {
                        return;
                    }
                    TweenMax.to(e.currentTarget,self.options.nav.activeDuration,{
                        autoAlpha:1,
                        ease:self.options.nav.activeEase
                    });
                });
                this.$prev.mouseout(function(e) {
                    TweenMax.to(e.currentTarget,self.options.nav.activeDuration,{
                        autoAlpha:self.options.nav.alpha,
                        ease:self.options.nav.activeEase
                    });
                });
                this.$next.mouseover(function(e) {
                    if(self.current>=self.iSlidesIndexed) {
                        return;
                    }
                    TweenMax.to(e.currentTarget,self.options.nav.activeDuration,{
                        autoAlpha:1,
                        ease:self.options.nav.activeEase
                    });
                });
                this.$next.mouseout(function(e) {
                    TweenMax.to(e.currentTarget,self.options.nav.activeDuration,{
                        autoAlpha:self.options.nav.alpha,
                        ease:self.options.nav.activeEase
                    });
                });
            }
            
            /**
             * trigger on window resize
             * 
             * @returns {undefined}
             */
            this.onResize=function()
            {
                this.positionMegaSliderBg();
                
                
                this.iWidthCurrent=this.iWidth;

                this.setMatrix();
                
                //break here if width does not differ
                if(this.iWidthCurrent===this.iWidth) {
                    return;
                }
                
                if(this.resized===false) {
                    this.oSlides[this.current].li.addClass(this.options.event.resizeClass);
                    this.resized=true;
                }
                for(var i=0;i<this.iSlides;i++) {
                    this.oSlides[i].li.width(this.iWidth);
                }
                TweenMax.set(this.$ul,{
                    x:(this.current * this.iWidth *-1),
                    force3D:'auto'
                });
                
                if(true===this.isMobile) {
                    if(this.resizedToMobile===false) {
                        this.$el.addClass(this.options.animate.init.mobileClass);
                        this.resizedToMobile=true;
                    }
                } else {
                    if(this.resizedToMobile===true) {
                        this.$el.removeClass(this.options.animate.init.mobileClass);
                        this.resizedToMobile=false;
                    }
                }
                
                //adapt elements of current slide
                if(true===this.busy) {
                    
                } else {
                    for(var i=0;i<this.oSlides[this.current].length;i++) {
                        var el=this.oSlides[this.current].el.eq(i);
                        TweenMax.set(el,{
                            scale:this.getScale(el),
                            x:this.getX(el),
                            force3D:'auto'
                        });
                    }
                }
                
                //update tween vars of all timelines
                var fScale,iXFrom,iXTo;
                for(var key in this.oTweenElement.phase0) {
                    fScale=this.getScale(this.oTweenElement.phase0[key].el);
                    iXFrom=this.getX(this.oTweenElement.phase0[key].el, this.data.startx);
                    iXTo=this.getX(this.oTweenElement.phase0[key].el, this.data.targetx);
                    
                    //I) set from if given
                    if(this.oTweenElement.phase0[key].tween.vars.startAt!==undefined) {
                        this.oTweenElement.phase0[key].tween.vars.startAt.scale=fScale;
                        this.oTweenElement.phase0[key].tween.vars.startAt.x=iXFrom;
                    }
                    //II) set to
                    if(true===this.oTweenElement.phase0[key].tween._initted) {
                        this.oTweenElement.phase0[key].tween.vars.css.scale=fScale;
                        this.oTweenElement.phase0[key].tween.vars.css.x=iXTo;
                    } else {
                        this.oTweenElement.phase0[key].tween.vars.scale=fScale;
                        this.oTweenElement.phase0[key].tween.vars.x=iXTo;
                    }
                }
                
                var fScale1,iXFrom1,iXTo1;
                for(var key2 in this.oTweenElement.phase1) {
                    fScale1=this.getScale(this.oTweenElement.phase1[key2].el);
                    iXFrom1=this.getX(this.oTweenElement.phase1[key2].el, this.data.targetx);
                    iXTo1=this.getX(this.oTweenElement.phase1[key2].el, this.data.endx);
                    
                    //I) set from if given
                    if(this.oTweenElement.phase1[key2].tween.vars.startAt!==undefined) {
                        this.oTweenElement.phase1[key2].tween.vars.startAt.scale=fScale1;
                        this.oTweenElement.phase1[key2].tween.vars.startAt.x=iXFrom1;
                    }
                    //II) set to
                    if(true===this.oTweenElement.phase1[key2].tween._initted) {
                        this.oTweenElement.phase1[key2].tween.vars.css.scale=fScale1;
                        this.oTweenElement.phase1[key2].tween.vars.css.x=iXTo1;
                    } else {
                        this.oTweenElement.phase1[key2].tween.vars.scale=fScale1;
                        this.oTweenElement.phase1[key2].tween.vars.x=iXTo1;
                    }
                }
                
                var fScale2,iXTo2;
                for(var key2 in this.oTweenElement.set0) {
                    fScale2=this.getScale(this.oTweenElement.set0[key2].el);
                    iXTo2=this.getX(this.oTweenElement.set0[key2].el, this.data.startx);
                    
                    //I) set0 to
                    if(true===this.oTweenElement.set0[key2].tween._initted) {
                        this.oTweenElement.set0[key2].tween.vars.css.scale=fScale2;
                        this.oTweenElement.set0[key2].tween.vars.css.x=iXTo2;
                    } else {
                        this.oTweenElement.set0[key2].tween.vars.scale=fScale2;
                        this.oTweenElement.set0[key2].tween.vars.x=iXTo2;
                    }
                }
                
                var fScale3,iXTo3;
                for(var key3 in this.oTweenElement.set1) {
                    fScale3=this.getScale(this.oTweenElement.set1[key3].el);
                    iXTo3=this.getX(this.oTweenElement.set1[key3].el, this.data.targetx);
                    
                    //I) set1 to
                    if(true===this.oTweenElement.set1[key3].tween._initted) {
                        this.oTweenElement.set1[key3].tween.vars.css.scale=fScale3;
                        this.oTweenElement.set1[key3].tween.vars.css.x=iXTo3;
                    } else {
                        this.oTweenElement.set1[key3].tween.vars.scale=fScale3;
                        this.oTweenElement.set1[key3].tween.vars.x=iXTo3;
                    }
                }
                
                //update mobile
                for(var key4 in this.oTweenElement.mobile) {
                    //I) set from if given
                    if(this.oTweenElement.mobile[key4].tween.vars.startAt!==undefined) {
                        this.oTweenElement.mobile[key4].tween.vars.startAt.x=(this.iWidth *this.oTweenElement.mobile[key4].current *-1);
                    }
                    
                    //II) set to
                    if(true===this.oTweenElement.mobile[key4].tween._initted) {
                        this.oTweenElement.mobile[key4].tween.vars.css.x=(this.iWidth *this.oTweenElement.mobile[key4].goto *-1);
                    } else {
                        this.oTweenElement.mobile[key4].tween.vars.x=(this.iWidth *this.oTweenElement.mobile[key4].goto *-1);
                    }
                }
            };
            
            
            
            
            this.positionMegaSliderBg = function()
            {
                var $megaSlider = $('.megageiler_homepage_slider');
                var $megaSliderWidth = $megaSlider.width();
                var $megaSliderImg = $('.megageiler_homepage_slider .bg img');
                var $megaSliderImgWidth = $megaSliderImg.width();
                //console.log($megaSliderWidth);
                //console.log($megaSliderImgWidth);
                var right = -($megaSliderImgWidth - $megaSliderWidth) /2;
                $megaSliderImg.css({'right': right});
            };
            
            /**
             * handle swipe event
             * 
             * @returns {undefined}
             */
            this.handleSwipe=function(e)
            {
                this.iCurrent=self.pageX(e);
                this.iStatusPrevious=this.iStatus || 0;
                this.iStatus=(this.iCurrent-self.iTouchStart);
                
                this.iCurrentY=self.pageY(e);
                this.iStatusYPrevious=this.iStatusY || 0;
                this.iStatusY=(this.iCurrentY-self.iTouchStartY);
                
                //trigger on vertical swipe only
                if(Math.abs(this.iStatus-this.iStatusPrevious) <= Math.abs(this.iStatusY-this.iStatusYPrevious)) {
                    return;
                }
                
                e.preventDefault();
                
                if(false!==this.busy) {
                    return;
                }
                
                if(this.iStatus<0) {
                    self.bFwd=true;
                    //break here if we are on last slide already
                    /** @see SOPGSITE-45: enable loop
                    if(self.current>=self.iSlidesIndexed) {
                       return;
                    }
                    */
                } else {
                    self.bFwd=false;
                    //break here if we are on first slide already
                    /** @see SOPGSITE-45: enable loop
                    if(self.current<=0) {
                        return;
                    }
                    */
                }
                var fProgress=Math.abs(this.iStatus)/this.iWidth;
                
                //set initial
                if(true!==this.busyTouch) {
                    this.busyTouch=true;
                    this.gotoSwipe=(true===self.bFwd) ? this.goto+1 : this.goto-1;
                    
                    if(this.gotoSwipe>this.iSlidesIndexed) {
                        this.gotoSwipe=0;
                    }
                
                    if(this.gotoSwipe<0) {
                        this.gotoSwipe=this.iSlidesIndexed;
                    }
                
                    //check if tl exists, create one if not
                    this.device=(true===this.isMobile) ? 'mobile' : 'desktop';
                    if(true!==this.oTimeline[this.device][this.current].hasOwnProperty(this.gotoSwipe)) {
                        this.appendNewTimeline(this.current,this.gotoSwipe);
                    }
                    this.oTimeline[this.device][this.current][this.gotoSwipe].seek(0).invalidate().pause();
                } else {
                    if(fProgress>this.options.event.swipe.ignition) {
                        this.goto=this.gotoSwipe;
                        this.triggerAnimateTouch();
                    } else {
                        this.oTimeline[this.device][this.current][this.gotoSwipe].progress(fProgress);
                    }
                }
            };
            
            /**
             * handle on event touchend
             * @returns {undefined}
             */
            this.handleSwipeEnd=function()
            {
                if(
                        (false===this.busy)
                        && (true===this.busyTouch) 
                ) {
                    this.busy=true;
                    this.oTimeline[this.device][this.current][this.gotoSwipe].reverse();
                }
            };
            
            /**
             * get pageX
             * 
             * @param {object} e
             * @returns {int}
             */
            this.pageX=function(e)
            {
                if(true===e.originalEvent.hasOwnProperty('touches')) {
                    return e.originalEvent.touches[0].pageX;
                } else {
                    return e.originalEvent.changedTouches[0].pageX;
                }
            };
            
            /**
             * get pageY
             * 
             * @param {object} e
             * @returns {int}
             */
            this.pageY=function(e)
            {
                if(true===e.originalEvent.hasOwnProperty('touches')) {
                    return e.originalEvent.touches[0].pageY;
                } else {
                    return e.originalEvent.changedTouches[0].pageY;
                }
            };
        };
        
        return sliderEvent;
    }
);
