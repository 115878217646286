/**
 * define sliderSet
 * 
 * @provide set options
 * @provide init elements
 * @param {type} $
 * @returns {undefined}
 * @version 0.1
 * @date 15/07/03
 * @author sh
 */
define(
    'modules/home-slider/slider.set',[
        'jquery'
    ],
    function($) 
    {
        var sliderSet = function() 
        {
            'use strict';

            /**
             * set global instance
             */
            var self = this;
            
            /**
             * init some options
             * 
             * @provide deferred
             * @returns {undefined}
             */
            this.initOptions=function()
            {
                var deferred=new $.Deferred();
                
                this.$el=$(this.selector.el);
                if(this.$el.length<=0) {
                    return;
                }
                
                //frame
                this.$window=$(window);
                this.$body=$('body');
                this.$paginationWrapper=this.$el.find(this.selector.pagination);
                this.$prevWrapper=this.$el.find(this.selector.prev);
                this.$prev=this.$prevWrapper.find(this.selector.navigationButton);
                this.$nextWrapper=this.$el.find(this.selector.next);
                this.$next=this.$nextWrapper.find(this.selector.navigationButton);
                this.$progress=this.$el.find(this.selector.progress);
                
                //bg images
                this.$bgStd=this.$el.find(this.selector.bg_std);
                this.$bgBlur=this.$el.find(this.selector.bg_blur);
                
                //ul
                this.$ul=this.$el.find(this.selector.ul);
                this.$li=this.$ul.find(this.selector.li);
                this.iLi=this.$li.length;
                
                //li
                this.oSlides={};
                for(var i=0;i<this.iLi;i++) {
                    var oSlide=this.$li.eq(i).find(this.selector.slide);
                    this.oSlides[i]={
                        li:this.$li.eq(i),
                        el:oSlide,
                        length:oSlide.length
                    };
                    
                    this.$li.eq(i).find(this.selector.resizeWrapper).css({
                        maxWidth:this.options.breakpoint.resizeMax
                    });
                    
                    if(i>=(this.iLi-1)) {
                        deferred.resolve();
                    }
                }
                
                this.iSlides=this.objectLength(this.oSlides);
                this.iSlidesIndexed=this.iSlides-1;
                
                return deferred.promise();
            };
            
            /**
             * set/reset basic matrix params
             * 
             * @returns {undefined}
             */
            this.setMatrix=function()
            {
                this.iWidth=this.$el.width();
                this.iWidthMax=Math.min(this.options.breakpoint.resizeMax,this.iWidth);
                this.iHeight=this.$el.height() - (this.options.marginY *2);
                this.isMobile=(this.iWidth<this.options.breakpoint.mobile) ? true : false;
                if(this.iSlides>1) {
                    this.iHeight+=this.$paginationWrapper.position().top;
                }
            };
            
            /**
             * init some basic params
             * 
             * @require setMatrix already triggered
             * @returns {undefined}
             */
            this.initParams=function()
            {
                this.current=this.options.current;
                if(this.options.current>this.iSlides) {
                    this.current=0;
                }
                
                this.goto=this.current;
                this.autoplay=this.options.autoplay.play;
                this.busy=false;
                this.busyTouch=false;
                this.resized=false;
                this.resizedToMobile=this.isMobile;
                this.isTouch=(true===$.support.touch) ? true : false;
            };
            
            /**
             * init all elements of all slides
             * 
             * @provide deferred
             * @returns {undefined}
             */
            this.initElements=function()
            {
                if(true!==this.requirementsAreMet()) {
                    return;
                }
                
                //iterate through all elements of all slides
                for(var i=0;i<this.iSlides;i++) {
                    //init li
                    TweenMax.set(this.oSlides[i].li,{
                        autoAlpha:0,
                        width:this.iWidth
                    });
                    
                    //init all elements inside li
                    for(var y=0;y<this.oSlides[i].length;y++) {
                        //set dimensions
                        this.oSlides[i].el.eq(y).data(this.data.width,this.oSlides[i].el.eq(y).width());
                        this.oSlides[i].el.eq(y).data(this.data.height,this.oSlides[i].el.eq(y).height());
                        
                        //set inside start definition
                        this.setStart(this.oSlides[i].el.eq(y));
                    }
                }
            };
            
            /**
             * init pagination
             * 
             * @returns {undefined}
             */
            this.initPagination=function()
            {
                if(this.iSlides<=1 || !this.$paginationWrapper || this.$paginationWrapper.length === 0) {
                    return;
                }
                
                TweenMax.set(this.$paginationWrapper,{autoAlpha:0});
                
                this.$pagination={};
                for(var i=0;i<this.iSlides;i++) {
                    this.$pagination[i]=$('<' + this.options.pagination.element + '/>',{
                        'class':(i===this.current) ? 'active' : ''
                    }).appendTo(this.$paginationWrapper);
                    this.$pagination[i].data(this.data.goto,i);
                }
                
                this.$paginationWrapper.width(this.iSlides *this.$pagination[0].outerWidth(true));
                
                TweenMax.set([this.$prev,this.$next],{autoAlpha:0});
            };
            
            /**
             * set start position for given element
             * 
             * @param {type} el
             * @returns {undefined}
             */
            this.setStart=function(el)
            {
                el.data(this.data.task,'start');
                TweenMax.set(el,{
                    y:this.getY(el),
                    x:this.getX(el),
                    scale:this.getScale(el),
                    force3D:'auto',
                    autoAlpha:0
                });
            };
            
            /**
             * map current task to data-name for x position
             * 
             * @param {type} el
             * @returns {slider.set_L16.sliderSet.getTaskX@pro;data@pro;startx}
             */
            this.getTaskX=function(el)
            {
                var sTask;
                
                switch(el.data(this.data.task)) {
                    case 'start':
                        sTask=this.data.startx;
                        break;
                    
                    case 'renderTarget':
                        sTask='renderTarget';
                        break;
                    
                    case 'target':
                        sTask=this.data.targetx;
                        break;
                    
                    case 'renderEnd':
                        sTask='renderEnd';
                        break;
                        break;
                        
                    case 'end':
                        sTask=this.data.endx;
                        break;
                    
                    default:
                        sTask=this.data.startx;
                        break;
                }
                
                return sTask;
            };
            
            /**
             * get y position for given element
             * 
             * @param {type} el
             * @returns {Number}
             */
            this.getY=function(el)
            {
                var remain=this.iHeight-el.data(this.data.height);
                
                var iReturn=Math.min(
                        remain,
                        Math.max(
                                0,
                                (
                                        remain
                                        * el.data(this.data.y)
                                        * 0.01
                                )
                        )
                ) + this.options.marginY;
        
                //adapt position on scale < 1
                var fScale=this.getScale(el);
                if(fScale<=1) {
                   // iReturn+=(el.data(this.data.height) - ((el.data(this.data.height) *fScale))) * (el.data(this.data.y) -50) *0.01;
                }
                
                return iReturn;
            };
            
            /**
             * get x position for given element
             * 
             * @param {type} el
             * @param {type} sData
             * @returns {Number}
             */
            this.getX=function(el,sData)
            {
                var iReturn=0;
                
                var x=sData || this.getTaskX(el);
                var remain=this.iWidthMax-el.data(this.data.width);
                iReturn=(
                        remain
                        * el.data(x)
                        * 0.01
                );
                
                //adapt position on scale < 1; otherwise disable off viewport on target-x
                var fScale=this.getScale(el);
                if(fScale<1) {
                    iReturn+=  ((el.data(this.data.width) - (el.data(this.data.width) *this.getScale(el))) *(( el.data(x)-50) *0.01) );
                } else if(x===this.data.targetx) {
                    iReturn=Math.max(0,iReturn);
                }
                
                return iReturn;
            };
            
            /**
             * get scale factor for given element
             * 
             * @provide whitlist by type
             * @param {type} el
             * @returns {@this;@pro;$el@call;width|Number|slider.set_L16.sliderSet.options.breakpoint.resizeMin}
             */
            this.getScale=function(el)
            {
                var fReturn=1;
                
                if(
                        (this.iWidth<this.options.breakpoint.resizeMin)
                        && (true===this.data.enableScale.hasOwnProperty(el.data(this.data.type)))
                ) {
                    fReturn=(this.iWidth/this.options.breakpoint.resizeMin);
                }
                
                return fReturn;
            };
        };

        return sliderSet;
    }
);

